<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="row mt-4">
          <div class="col-sm-12 col-md-6">
            <div id="tickets-table_length" class="dataTables_length">
              <label class="d-inline-flex align-items-center fw-normal">
                Show &nbsp;
                <b-form-select
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                ></b-form-select
                >&nbsp; entries
              </label>
            </div>
          </div>
          <!-- Search -->
          <div class="col-sm-12 col-md-6">
            <div
              id="tickets-table_filter"
              class="dataTables_filter text-md-end"
            >
              <label class="d-inline-flex align-items-center fw-normal">
                Search:
                <b-form-input
                  v-model="filter"
                  type="search"
                  class="form-control form-control-sm ms-2"
                ></b-form-input>
              </label>
            </div>
          </div>
          <!-- End search -->
        </div>

        <!-- Table -->
        <div class="table-responsive mb-0">
          <b-table
            table-class="table table-centered datatable table-card-list"
            thead-tr-class="bg-transparent"
            :items="userItems"
            :fields="fields"
            responsive="sm"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #head(fullName)="">
              <span>ชื่อ</span>
            </template>

            <template #head(loginType)="">
              <span>ประเภทการ login</span>
            </template>

            <template #head(mobile)="">
              <span>เบอร์โทรศัพท์</span>
            </template>

            <template #head(email)="">
              <span>อีเมล</span>
            </template>

            <template #head(status)="">
              <span>สถานะ</span>
            </template>

            <template #head(action)="">
              <span>ตั้งค่า</span>
            </template>

            <template v-slot:cell(status)="data">
              <span
                v-if="data.item.status === 'ยังไม่ดำเนินการ'"
                style="color: #BC1C1C;"
                >ยังไม่ดำเนินการ</span
              >
              <span
                v-if="data.item.status === 'อยู่ระหว่างการตรวจสอบ'"
                style="color: #F4BC00;"
                >อยู่ระหว่างการตรวจสอบ</span
              >
              <span v-if="data.item.status === 'สำเร็จ'" style="color: #65B52A;"
                >สำเร็จ</span
              >
              <span v-if="data.item.status === 'ยกเลิก'" style="color: #DDDDDD;"
                >ยกเลิก</span
              >
            </template>

            <template v-slot:cell(action)="row">
              <ul class="list-inline mb-0">
                <li class="list-inline-item">
                  <b-button
                    pill
                    variant="outline-primary"
                    @click="onUpdateBookingStatus(row.item)"
                  >
                    <i class="uil uil-pen font-size-18"></i
                  ></b-button>
                </li>
              </ul>
            </template>
          </b-table>
        </div>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-end">
              <ul class="pagination pagination-rounded">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import appConfig from "@/app.config";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

export default {
  components: { Layout, PageHeader },
  page: {
    title: "Booking Management",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  created() {
    const filters = {};
    this.$store.dispatch("user/getUserList", filters);
  },
  computed: {
    userItems() {
      return this.$store ? this.$store.state.user.userList : [];
    },
    rows() {
      if (this.userItems) {
        return this.userItems.length;
      }
      return 0;
    }
  },
  data() {
    return {
      title: "การจัดการ ผู้เข้าใช้ระบบ",
      items: [
        {
          text: ""
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "fullName",
          sortable: true
        },
        {
          key: "loginType",
          sortable: true
        },
        {
          key: "mobile",
          sortable: true
        },
        {
          key: "email",
          sortable: true
        },
        {
          key: "status",
          sortable: true
        },
        "action"
      ]
    };
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getFormatDate(date) {
      if (date) {
        let convertToDate = new Date(date);
        return new Date(
          convertToDate.getFullYear(),
          convertToDate.getMonth(),
          convertToDate.getDate()
        ).toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric"
        });
      } else {
        return "";
      }
    },
    onUpdateBookingStatus(item) {
      if (item) {
        this.$store.dispatch("user/setUpdateUserItem", item);
        this.$router.push({ path: "/usermanagement/update" });
      }
    }
  },
  middleware: "authentication"
};
</script>

<style scoped></style>
